<template>

    <div class="background">
  
            <el-input
    type="textarea"
    autosize
    placeholder="输入搜索内容"
    v-model="keyword">
    </el-input>
    <div>  搜索规则：device_id=xxxxx  <br> 按照规则填写即可</div>
      <el-button class="create-version-button" size="medium" type="primary" @click="Search()">搜索</el-button>

  
      <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column prop="device_id" label="设备 ID"/>
        <el-table-column prop="level" label="日志等级"/>
        <el-table-column prop="keyword" label="关键字"/>
        <el-table-column prop="app_info" label="贴牌信息(版本 / BundleID / 域名)"/>
        <el-table-column prop="device_info" label="设备信息(型号 / UDID / 版本)"/>
        <el-table-column prop="body" label="body"/>
        <el-table-column prop="" label="Http Header" align="center" >
        <template #default="scope">
          <el-button size="medium" type="normal" @click="ShowHttpHeader(scope.$index)">查看</el-button>
        </template>
        </el-table-column>

        <el-table-column prop="created_at" label="创建时间"/>
      </el-table>
  
    </div>
  </template>
  
  <script>
  
  import { Message } from 'element-ui'
  import { ListLogInfo } from '../../api/log_info'
  import { timestampToTime } from '../../utils/time'
  
  export default {
    name: 'LogInfo',
    components: {
    },
    data () {
      return {
        keyword: "device_id=",
        tableData: [],  
      }
    },
  
    mounted () {
      this.onLoad()
    },
  
    methods: {
      // 获取列表数据
      async onLoad() {
        const resp = await ListLogInfo(this.keyword)
        if (resp.error != null) {
          Message({message: "获取列表失败: " + resp.error.message, type: 'error', duration: 2 * 1000})
          return
        }
        
        resp.data.map((item)=>{

          item.created_at = timestampToTime(item.created_at)
          console.log(item)
          var app_info = `${item.app_version}   /   ${item.bundle_id}`  
          if (item.auth_app != null) {
            app_info = app_info + `   /   ${item.auth_app.domain}`
          }

          item.app_info = app_info

          item.device_info = `${item.device_product}   /   ${item.device_udid}   /   ${item.device_version}`
          return item
        });
  
        this.tableData = resp.data
      },

      Search() {
        this.onLoad()
      },

      ShowHttpHeader(index) {
        this.$alert(this.tableData[index].http_header, 'Http Header', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  
  .background {
    background-color: lavenderblush;
    position: relative;
    overflow-y: auto;
    display: block;
  
  .create-version-button{
    float:right;
    margin:10px;
  }
  
  }


  </style>
  