<template>
  <div>

    <div class="top-reaction">
      <el-row>
        <el-button class="selected-all-button"  @click="ClickSelectedAllButton()">
          <template style="text-align: left">
            <div v-if="isSelectedAllButton == true">取消全选</div>
            <div v-if="isSelectedAllButton == false">全 选</div>
          </template>
        </el-button>

        <el-date-picker
          v-model="selectedDate"
          type="daterange"
          value-format="timestamp"
          format="yyyy-MM-dd"
          dateType="time"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          size="default"
          @change="HandleDateRangeChange"
        />

      </el-row>

    </div>


    <div class="echart" id="mychart" :style="{ float: 'left', width: '100%', height: '800px' }"></div>

  </div>

</template>

<script>

import * as echarts from "echarts";
import { GetStatistics } from '../../api/data'
import { TimestampToDayTime } from '../../utils/time'
import { Message } from "element-ui";

export default {
  data() {
    let defaultEndAt = new Date()
    let defaultStartAt = new Date()
    defaultStartAt.setTime(defaultStartAt.getTime() - 3600 * 1000 * 24 * 7)
    return {
      selectedDate: [defaultStartAt.getTime(), defaultEndAt.getTime()],   // 这是毫秒级时间戳

      isSelectedAllButton: true,

      chart: null,
      chartOption: null
    };
  },
  mounted() {
    this.initEcharts();
  },
  methods: {
    async initEcharts() {
      const resp = await GetStatistics(parseInt(this.selectedDate[0] / 1000), parseInt(this.selectedDate[1] / 1000))
      if (resp.error != null) {
        Message({message: "获取数据失败: " + resp.error.message, type: 'error', duration: 2 * 1000})
        return
      }

      const users = []
      const selectedData = {}

      const xData = []
      const seriesData = []

      resp.data.forEach(function (item, index) {

        const user = item.app_name + "(" + item.bundle_id + ")"
        users.push(user)

        selectedData[user] = true

        // 只添加一次 x 轴数据
        if (index === 0) {
          item.statistics.forEach(function (statistic) {
            xData.push(TimestampToDayTime(statistic.time))
          })
        }

        // 添加数据
        const counts = []
        item.statistics.forEach(function (statistic) {
          counts.push(statistic.open_app_count)
        })

        seriesData.push({
          name: user,
          type: 'line',
          // stack: 'Total',    // 这里控制是否叠加
          data: counts,
        })
      })

      // 扩充数组
      let totalCounts = []
      seriesData.forEach(function (item) {
        if (totalCounts.length === 0) {
          item.data.forEach(function () {
            totalCounts.push(0)
          })
        }
      })
      // 填充 count 数据
      seriesData.forEach(function (item, index) {
        item.data.forEach(function (count, idx) {
          totalCounts[idx] += count
        })
      })
      // 填入数据
      let totalTitle = '总计'
      users.push(totalTitle)
      seriesData.push({
        name: totalTitle,
        type: 'line',
        data: totalCounts
      })
      selectedData[totalTitle] = true

      this.chartOption = {
        title: {
          text: '启动次数 共(' + resp.data.length + ')个',
          top: 14,
          show: true,
          left: "center"
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
        },
        legend: {
          top: 600,
          // type: 'scroll',
          left: '5%',
          right: '5%',
          data: users,
          selected: selectedData,
          // selectedMode: 'single' // 强制单选模式
        },
        grid: {
          top: '10%',
          left: '3%',
          right: '4%',
          bottom: '30%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          name: '日期',
          boundaryGap: false,
          data: xData
        },
        yAxis: {
          type: 'value',
          name: '启动次数',
          // axisLabel: {
          //   formatter: function (value, index) {
          //     console.log(111, value)
          //     // 在这里可以自定义 Y 轴标签的显示内容
          //     // 例如将显示的数字除以 1000，然后添加单位为 'k'
          //     return (value / 1000).toFixed(0) + 'k';
          //   }
          // },
        },
        series: seriesData
      }

      if (this.chart == null) {
        this.chart = echarts.init(document.getElementById("mychart"));// 图标初始化
        //随着屏幕大小调节图表
        window.addEventListener("resize", () => {
          this.chart.resize();
        });
      }
      this.chart.setOption(this.chartOption);// 渲染页面
    },

    ClickSelectedAllButton() {
      this.isSelectedAllButton = !this.isSelectedAllButton
      for (let key in this.chartOption.legend.selected) {
        this.chartOption.legend.selected[key] = this.isSelectedAllButton
      }
      this.chart.setOption(this.chartOption);// 渲染页面
    },

    HandleDateRangeChange() {
      this.initEcharts()
    }
  }
}
</script>

<style>

.top-reaction {
  float:right;
  margin:10px;

  .selected-all-button {
    margin-right:20px;
  }
}

</style>
