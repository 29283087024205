import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI  from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css';
// 引入cookie
import jsCookie from 'js-cookie'
import cookie from 'vue-cookie'
// 复制组件
import VueClipBoard from 'vue-clipboard2'
// 导入 axios
import axios from 'axios'
import { Tabbar, Col, Row, Image as VanImage, TabbarItem, NavBar, List, NoticeBar, Swipe, SwipeItem, Lazyload, PullRefresh, TreeSelect, Sidebar, SidebarItem, Search, Button, Cell, DropdownMenu, DropdownItem, Panel, ContactCard, Tag, Divider, Toast, Popup, RadioGroup, Radio, Dialog, Field,Form ,CellGroup} from 'vant'
Vue.config.productionTip = false

// 把 axios 挂载到 Vue 原型上. 今后,在每个 .vue 组件中发起请求,直接调用 this.$http 即可.
Vue.prototype.$http = axios
// jsCookie
Vue.prototype.$cookie = jsCookie

Vue.use(Form);
Vue.use(Field);
Vue.use(CellGroup);
Vue.use(Col)
Vue.use(Row)
Vue.use(VanImage)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(NavBar)
Vue.use(List)
Vue.use(NoticeBar)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Lazyload)
Vue.use(PullRefresh)
Vue.use(TreeSelect)
Vue.use(Sidebar)
Vue.use(SidebarItem)
Vue.use(Search)
Vue.use(Button)
Vue.use(List)
Vue.use(Cell)
Vue.use(Panel)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(ContactCard)
Vue.use(Tag)
Vue.use(Divider)
Vue.use(Toast)
Vue.use(Popup)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Dialog)
Vue.use(Field)
Vue.use(VueClipBoard)

Vue.prototype.$axios = cookie;

// Vue.use(Loading)

Vue.use(ElementUI, {size: 'small', zIndex: 3000});
new Vue({
  router,
  el: '#app',
  render: h => h(App)
}).$mount('#app')
