import request from '@/utils/request'


export function CheckPassword (data) {
  return request({
    url: '/admin/check_password',
    method: 'post',
    data: data
  })
}
