<template>
  <div class="background">

    <el-button size="medium"  class="auth-btn" type="primary" @click="createAuthApp()">授权 BundleID</el-button>

    <el-button size="medium"  class="auth-btn" type="primary" @click="searchAuthApp()">按域名搜索</el-button>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="id" label="ID" align="center"/>
      <el-table-column prop="config.app_name" label="名称" align="center"/>
      <el-table-column prop="domain" label="域名" align="center"/>
      <el-table-column prop="bundle_id" label="bundle_id" align="center"/>
      <el-table-column prop="config.selected_version" label="选中的版本" align="center"/>
      <el-table-column prop="regulate_status_string" label="管控状态" align="center"/>
      <el-table-column prop="created_at" label="创建时间" align="center"/>
      <el-table-column prop="" label="查看配置" align="center" >
        <template #default="scope">
          <el-button
            size="medium"
            type="primary"
            @click="selectedConfig(scope.$index)"
          >查看</el-button
          >
        </template>
      </el-table-column>

      <el-table-column prop="" label="删除" align="center">
        <template #default="scope">
          <el-button
            size="medium"
            type="danger"
            @click="removeConfig(scope.$index)"
          >删除</el-button
          >
        </template>
      </el-table-column>

    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        :page-size="pageSize"
        :pager-count="5"
        layout="prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
    <!-- 配置表单 -->
    <el-dialog title="配置信息" :visible.sync="dialogFormVisible" width="900px">
      <el-form :model="selectedFormData">

        <el-form-item label="ID">
          <el-input readonly v-model="selectedFormData.id" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="Bundle ID">
          <el-input readonly v-model="selectedFormData.bundle_id" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="代理选中的版本">
          <el-input readonly v-model="selectedFormData.selected_version" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="dumpapp token">
          <el-input v-model="selectedFormData.dumpapp_token" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="是否允许 token 重复">
          <el-switch v-model="selectedFormData.is_repeat_dumpapp_token" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </el-form-item>

        <el-form-item label="管控状态">
          <el-select v-model="selectedFormData.regulate_status_string" placeholder="请选择管控状态">
            <el-option v-for="(item, index) in regulateStatusData" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="域名">
          <el-input v-model="selectedFormData.domain" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="App Name">
          <el-input v-model="selectedFormData.app_name" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="App Icon">
          <el-input v-model="selectedFormData.app_icon_url" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="主题色">
          <el-input v-model="selectedFormData.app_theme_color" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="联系地址">
          <el-input v-model="selectedFormData.after_sale_service_url" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="默认软件源">
          <div v-for="(item, index) in defaultAppSource" :key="index">
            <el-input placeholder="输入默认软件源" v-model="item.value" class="handle-input mr10"></el-input>
            <el-button type="danger" @click="removeDefaultAppSource(item, index)"> 删除 </el-button>
          </div>
          <el-button type="primary" @click="addDefaultAppSource()"> 添加 </el-button>

        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickFormTableButton(false)">取 消</el-button>
        <el-button type="primary" @click="clickFormTableButton(true)">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import { Message } from 'element-ui'
import { AppAuthList, PutAppAuth, DeleteAppAuth, PostAppAuth } from '../../api/app_auth'
import { timestampToTime } from '../../utils/time'

export default {
  name: 'AuthBundleID',
  components: {
  },
  data () {
    const regulateStatusData = [
      {
        "value": 0,
        "label": "无管控"
      },
      {
        "value": 1,
        "label": "闪退"
      },
      {
        "value": 2,
        "label": "sleep"
      },
      {
        "value": 3,
        "label": "死循环内存"
      },
      {
        "value": 4,
        "label": "取消授权"
      }
    ]

    return {
      tableData:[],
      // limit: 500,

      dialogFormVisible: false,

      selectedFormData: {
        id: '',
        bundle_id: '',
        domain: '',

        selected_version: '', // 选中的版本
        dumpapp_token: '',
        is_repeat_dumpapp_token: false,

        app_name: '',
        app_icon_url: '',
        app_theme_color: '',

        regulate_status: 0,
        regulate_status_string: '',

        after_sale_service_url: '',

        default_app_source_urls: [],
      },

      defaultAppSource: [],
      regulateStatusData: regulateStatusData,
      currentPage: 1,
      total:0,
      pageSize:2000
    }
  },
  created () {
  },

  mounted () {
    this.onLoad("")
  },

  methods: {
    async onLoad(keyword) {
      const res = await AppAuthList(this.tableData.length, this.pageSize, keyword);
      if (res.error != null) {
        Message({message: "获取列表失败: " + res.error.message, type: 'error', duration: 2 * 1000})
        return
      }
      this.total = res.paging?.totals || 0;
      const _this = this
      res.data.map((item)=>{
        let regulate_status_string = '';
        for (let i = 0; i < _this.regulateStatusData.length; i++) {
          if (i === item.regulate_status) {
            regulate_status_string = _this.regulateStatusData[i].label
          }
        }
        item.regulate_status_string = regulate_status_string

        item.created_at = timestampToTime(item.created_at)
        return item
      });

      this.tableData.push(...res.data)
    },
    async refresh(keyword) {
      this.tableData = []
      await this.onLoad(keyword)
    },

    selectedConfig(idx) {
      const selectedData = this.tableData[idx]

      var regulate_status_string = ''
      for (var i = 0; i < this.regulateStatusData.length; i++) {
        if (i === selectedData.regulate_status) {
          regulate_status_string = this.regulateStatusData[i].value
        }
      }

      this.selectedFormData = {
        id: selectedData.id,
        bundle_id: selectedData.bundle_id,
        domain: selectedData.domain,
        selected_version: selectedData.config.selected_version,
        dumpapp_token: selectedData.config.dumpapp_token,
        is_repeat_dumpapp_token: selectedData.config.is_repeat_dumpapp_token,

        regulate_status: selectedData.regulate_status,
        regulate_status_string: regulate_status_string,

        app_name: selectedData.config.app_name,
        app_icon_url: selectedData.config.app_icon_url,
        app_theme_color: selectedData.config.app_theme_color,

        after_sale_service_url: selectedData.config.after_sale_service_url,

        default_app_source_urls: selectedData.config.default_app_source_urls,
      }

      this.defaultAppSource = []
      for (var i = 0; i < this.selectedFormData.default_app_source_urls.length; i++) {
        this.defaultAppSource.push({
          "index": i,
          "value": this.selectedFormData.default_app_source_urls[i]
        })
      }

      this.dialogFormVisible = true
    },

    async createAuthApp() {
      this.$prompt('请输入授权的 BundleID', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then( async ({ value }) => {
        let data = await PostAppAuth({"bundle_id": value})
        if (data.error != null) {
          Message({message: "创建失败: " + data.error.message, type: 'error', duration: 2 * 1000})
          return
        }
        await this.refresh("")
        Message({message: "创建成功", type: 'success', duration: 2 * 1000})
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },

    async searchAuthApp() {
      this.$prompt('请输入要搜索的域名', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then( async ({ value }) => {
        await this.refresh(value)
        Message({message: "搜索成功", type: 'success', duration: 2 * 1000})
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },

    async removeConfig(index) {
      this.$confirm('此操作将永久删除该配置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let data = await DeleteAppAuth(this.tableData[index].id)
        if (data.error != null) {
          Message({message: "删除失败: " + data.error.message, type: 'error', duration: 2 * 1000})
          return
        }
        await this.refresh("")
        Message({message: "删除成功", type: 'success', duration: 2 * 1000})
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    async clickFormTableButton(confirm) {
      if (confirm === false) {
        this.dialogFormVisible = false
        return
      }

      var regulate_status = 0
      for (var i = 0; i < this.regulateStatusData.length; i++) {
        if (this.regulateStatusData[i].value === this.selectedFormData.regulate_status_string) {
          regulate_status = i
        }
      }
      this.selectedFormData.regulate_status = regulate_status

      var defaultAppSourceURLs = []
      for (var i = 0; i < this.defaultAppSource.length; i++) {
        defaultAppSourceURLs.push(this.defaultAppSource[i].value)
      }
      this.selectedFormData.default_app_source_urls = defaultAppSourceURLs

      const data = await PutAppAuth(this.selectedFormData.id, this.selectedFormData)

      this.dialogFormVisible = false

      if (data.error != null) {
        Message({message: "修改失败: " + data.error.message, type: 'error', duration: 2 * 1000})
      } else {
        Message({message: "修改成功", type: 'success', duration: 2 * 1000})
        await this.refresh("")
      }
    },
    },

    removeDefaultAppSource(item, index) {
      console.log(11, index)
      if (this.defaultAppSource.length <= 1) {
        Message({message: "最少保留 1 个软件源", type: 'error', duration: 2 * 1000})
        return
      }
      this.defaultAppSource.splice(index,1);
    },
    addDefaultAppSource() {
      this.defaultAppSource.push({
        "index": this.defaultAppSource.length,
        "value": "",
      })
    },
    // async handleSizeChange(val) {
    //     console.log(`每页 ${val} 条`);
    //     this.pageSize = val;
    //     const res = await AppAuthList(this.tableData.length, this.pageSize);
    // },
    // async handleCurrentChange(val) {
    //   console.log(`当前页: ${val}`);
    //   this.currentPage = val;
    //   const res = await AppAuthList(this.tableData.length, this.pageSize);
    // }
}
</script>

<style scoped lang="scss">
.background {
  background-color: lavenderblush;
}

.el-table .cell {
  white-space: pre-line;
}
.auth-btn{
  float:right;
  margin:10px;
}
.pagination-wrap{
  float: right;
  margin:10px 30px;
}

</style>
