import request from '@/utils/request'

export function AppAuthList (offset, limit, keyword) {
  var url = '/admin/app_auth/list?offset=' + offset + "&limit=" + limit + "&keyword=" + keyword
  return request({
    url: url,
    method: 'get',
  })
}

export function PutAppAuth (id,data) {
  return request({
    url: '/admin/app_auth/' + id,
    method: 'put',
    data: data
  })
}

export function DeleteAppAuth (id,data) {
  return request({
    url: '/admin/app_auth/' + id,
    method: 'delete',
    data: data
  })
}

export function PostAppAuth (data) {
  return request({
    url: '/admin/app_auth',
    method: 'post',
    data: data
  })
}
