import request from '@/utils/request'


export function ListLogInfo (query) {

  return request({
    url: '/admin/log_info?' + query,
    method: 'get',
  })
}
