import axios from 'axios'
import {Loading, Message} from 'element-ui'

import { GetPassword } from "../store";

export var baseURL = 'https://athena.dumpapp.com'
// export var baseURL = 'http://127.0.0.1:1999'

// create an axios instance
const service = axios.create({
  baseURL: baseURL,
  timeout: 30000,
  withCredentials: true,
  crossDomain: true
})

// let loading
let isLoginUrl

let loading

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    config.headers['x-admin-password'] = GetPassword();

    loading = Loading.service({
      body: true
    })

    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// response interceptor
service.interceptors.response.use(
  response => {
    loading.close()
    if (response.status === 200 && isLoginUrl) {
      window.location.href = `${window.location.origin}`
    }
    if (typeof response.data === 'string') {
      console.log(response.headers)
    }
    return response.data
  },

  error => {
    loading.close()
    let message
    if (error.response) {
      switch (error.response.status) {
        case 401:
          console.log('error object1')
          if (typeof error.response.data === 'string') {
            message = error.response.data
          } else if (typeof error.response.data === 'object') {
            message = error.response.data.message || error.response.data.error.message
          }
          return error.response.data
        case 403:
          console.log('error object2')

          if (typeof error.response.data === 'string') {
            message = error.response.data
          } else if (typeof error.response.data === 'object') {
            message = error.response.data.message || error.response.data.error.message
          }

          message = message || '服务器端错误'
          return error.response.data
      }
      return error.response.data
      // console.log('error object3', error.response.data)
      // if (typeof error.response.data === 'string') {
      //   message = error.response.data
      // } else if (typeof error.response.data === 'object') {
      //   message = error.response.data.message || error.response.data.error.message
      // }
    } else {
      message = error.message
    }
    message = message || '服务器端错误'
    console.log('message:', message)
    return Promise.reject(error)
  }
)

export default service
