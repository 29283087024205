import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'

import UV from '../views/data/uv.vue'
import OpenApp from '../views/data/open_app.vue'
import SignApp from '../views/data/sign_ipa.vue'
import NewUser from '../views/data/new_user.vue'
import LogInfo from '../views/data/log_info.vue'

import AuthBundleID from '../views/setting/auth_bundle_id.vue'
import AppVersion from '../views/setting/app_version.vue'
import AppVersionAdmin from '../views/setting/app_version_admin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    component: Home,
    index: "1",
    children:[],
  },

  // 数据分析
  {
    path: '/data',
    name: '数据分析',
    component: Home,
    index: "2",
    children:[{
      path: '/data/uv',
      name: 'uv',
      component: UV,
      index:"2-1",
    },{
      path: '/data/new_user',
      name: '新增用户',
      component: NewUser,
      index:"2-2",
    },{
      path: '/data/open_app',
      name: 'App 启动次数',
      component: OpenApp,
      index:"2-3",
    },{
      path: '/data/sign_app',
      name: 'App 签名次数',
      component: SignApp,
      index:"2-4",
    },{
      path: '/data/loginfo',
      name: '日志查询',
      component: LogInfo,
      index:"2-5",
    }],
  },

  // 设置
  {
    path: '/setting',
    name: '设置',
    component: Home,
    index: "3",
    children:[{
      path: '/setting/auth_bundle_id',
      name: '授权 BundleID',
      component: AuthBundleID,
      index:"3-1",
    },
      {
      path: '/setting/app_version',
      name: 'App 版本',
      component: AppVersion,
      index:"3-2",
      },
      {
        path: '/setting/app_version_admin',
        name: 'App 管理后台版本',
        component: AppVersionAdmin,
        index:"3-3",
      },
    ],
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to,from,next)=>{
  // console.log('to:', to)
  // console.log('from:', from)
  next()
})
export default router
