
var Password = ""

export function SetPassword(password) {
  Password = password
}

export function GetPassword() {
  return Password
}
