<template>
<div class="background">

  <div class="nav_container">
    <el-menu router mode="horizontal"
             @select="handleSelect">

        <el-submenu v-for="(item) in $router.options.routes" :key="item.path" :index="item.path">
          <template slot="title">{{item.name}}</template>
          <el-menu-item v-for="(children) in item.children" :key="item.path + '_' + children.path" :index="children.path">{{children.name}}</el-menu-item>
        </el-submenu>

        <el-button class="login-button" v-if="loginButtonIsVisible" type="primary" size="medium" @click="InputPassword">输入密码</el-button>

    </el-menu>
  </div>

  <div class="content"><router-view></router-view></div>

  <el-dialog :visible.sync="inputPasswordDialogIsVisible" width="900px">
    <el-form>
      <el-form-item label="密码" label-width='50px'>
        <el-input v-model="password" autocomplete="off" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="ClickInputPassword(false)">取 消</el-button>
        <el-button type="primary" @click="ClickInputPassword(true)">确 定</el-button>
      </span>
    </template>
  </el-dialog>

</div>
</template>

<script>

import { SetPassword } from '../store/index'
import { CheckPassword } from '../api/check_password'
import {Message} from "element-ui";

export default {
  name: 'home',
  components: {
  },
  computed: {
    routes() {
      return this.$router.options.routes
    },
  },
  data () {
    return {
      loginButtonIsVisible: true,
      currentSelectedKey: '',

      inputPasswordDialogIsVisible: false,
      password: ''
    }
  },
  created () {
  },

  mounted () {
    SetPassword("666")
  },

  methods: {

    handleSelect(key, keyPath) {
      if (this.currentSelectedKey === key) {
        return
      }
      this.currentSelectedKey = key
      this.$router.push({
        path: key,
      })
    },

    InputPassword() {
      this.inputPasswordDialogIsVisible = true
    },

    async ClickInputPassword(isConfirm) {
      this.inputPasswordDialogIsVisible = false
      if (isConfirm === false) {
        return
      }
      let data = {
        password: this.password
      }
      let resp = await CheckPassword(data)
      if (resp.error != null) {
        Message({message: resp.error.message, type: 'error', duration: 2 * 1000})
        return
      }

      Message({message: '验证成功', type: 'success', duration: 2 * 1000})

      this.loginButtonIsVisible = false

      SetPassword(this.password)
    }
  }
}
</script>

<style scoped lang="scss">
.background {
  /*background-color: lavenderblush;*/
}

.background {

  .nav_container {
    width: 100%;

    .login-button {
      float: right;
      margin:10px 30px;
    }
  }

  .content{
    /*border: 1px solid;*/
    width: 100%;
    height: 100%;
    /*min-height: 800px;*/
  }
}
</style>
