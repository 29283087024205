<template>

  <div class="background">

    <el-button class="create-version-button" size="medium" type="primary" @click="PublishAppVersion()">发布新版本</el-button>

    <!-- 配置表单 -->
    <el-dialog title="配置信息" :visible.sync="isVisiblePublishDialog" width="900px">
      <el-form :model="publishAppVersionData">
        <el-form-item label="版本号">
          <el-input v-model="publishAppVersionData.version" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="publishAppVersionData.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="publishAppVersionData.description" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="zip URL">
          <el-input v-model="publishAppVersionData.url" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="ClickPublishDialogButton(false)">取 消</el-button>
        <el-button type="primary" @click="ClickPublishDialogButton(true)">确 定</el-button>
      </div>
    </el-dialog>

    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column prop="id" label="id"/>
      <el-table-column prop="version" label="版本"/>
      <el-table-column prop="title" label="标题"/>
      <el-table-column prop="desc" label="描述"/>
      <el-table-column prop="url" label="zip URL"/>
      <el-table-column prop="created_at" label="发布时间"/>
      <el-table-column prop="" label="删除" align="center" >
        <template #default="scope">
          <el-button size="medium" type="danger" @click="DeleteAppVersion(scope.$index)">删除</el-button>
        </template>
      </el-table-column>

    </el-table>

  </div>
</template>

<script>

import { Message } from 'element-ui'
import {GetAppVersionAdminList, DeleteAppVersionAdminApi, PostAppVersionAdminApi} from '../../api/app_version_admin'
import { timestampToTime } from '../../utils/time'

export default {
  name: 'AppVersionAdmin',
  components: {
  },
  data () {
    return {
      tableData: [],

      isVisiblePublishDialog: false, // 用来控制输入框是否展示
      publishAppVersionData: {
        "version": '',
        "title": "",
        "description": "",
        "url": "",
      },
    }
  },

  mounted () {
    this.onLoad()
  },

  methods: {
    // 获取列表数据
    async onLoad() {
      const resp = await GetAppVersionAdminList(0, 100)
      if (resp.error != null) {
        Message({message: "获取列表失败: " + resp.error.message, type: 'error', duration: 2 * 1000})
        return
      }
      resp.data.map((item)=>{
        item.created_at = timestampToTime(item.created_at)
        item.is_force_update = item.is_force_update === true ? '是' : '否'
        return item
      });

      this.tableData = resp.data
    },

    // 打开打不表单面板
    async PublishAppVersion() {
      this.isVisiblePublishDialog = true
    },

    // 表单面板点击取消或确定
    async ClickPublishDialogButton(isOk) {
      this.isVisiblePublishDialog = false
      if (isOk === false) {
        return
      }

      const resp = await PostAppVersionAdminApi(this.publishAppVersionData)
      if (resp.error != null) {
        Message({message: "发布失败: " + data.error.message, type: 'error', duration: 2 * 1000})
        return
      }

      Message({message: '发布成功', type: 'success', duration: 2 * 1000})
      await this.onLoad()
    },

    // 删除 app 版本
    async DeleteAppVersion(index) {
      this.$confirm('此操作将永久删除该配置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let resp = await DeleteAppVersionAdminApi(this.tableData[index].id)
        console.log(resp)
        if (resp.error != null) {
          Message({message: "删除失败: " + data.error.message, type: 'error', duration: 2 * 1000})
          return
        }
        Message({message: '删除成功', type: 'success', duration: 2 * 1000})
        await this.onLoad()
      }).catch(() => {
      });
    }
  }
}
</script>

<style scoped lang="scss">

.background {
  background-color: lavenderblush;
  position: relative;
  overflow-y: auto;
  display: block;

.create-version-button{
  float:right;
  margin:10px;
}

}

</style>
