import request from '@/utils/request'


export function GetAppVersionAdminList(offset, limit) {
  return request({
    url: '/admin/app_version_admin?offset='+offset+'&limit='+limit,
    method: 'get'
  })
}

export function DeleteAppVersionAdminApi(id) {
  return request({
    url: '/admin/app_version_admin/' + id,
    method: 'delete',
  })
}

export function PostAppVersionAdminApi(data) {
  return request({
    url: '/admin/app_version_admin',
    method: 'post',
    data
  })
}

